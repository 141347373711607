<template>
    <q-page class="main">
        <div class="text-center m-0 vh-50 d-flex flex-column justify-content-center text-dark">
            <img class="mx-auto mb-4" width="100px" src= "favicon.png" alt = "iSLA" />
            <p v-if="language.current=='en'">
                iSLA is an interactive online version of the Slovenian Linguistic Atlas (SLA), 
                which provides free access to cartographic, visual and audio-dialectological material and at the same time enables crowd sourcing, 
                through which users can add points – local dialects and dialect recordings of individual words.
            </p>
            <p v-else>
                iSLA je interaktivna spletna različica Slovenskega lingvističnega atlasa (SLA), 
                ki omogoča prost dostop do kartografskega, slikovnega in zvočnega dialektološkega gradiva, hkrati pa omogoča tudi množičenje podatkov, 
                preko katerega uporabniki lahko dodajajo točke - krajevne govore in narečne zapise posameznih besed.
            </p>
            <q-separator size="1rem" color="white"/>
            <img class="mx-auto mt-5" width="200px" src= "Logotip ZRC SAZU.png" alt = "ZRC SAZU" />
            <p class="kreon">Inštitut za slovenski jezik Frana Ramovša</p>
            <q-separator size="1rem" color="white"/>
            <img class="mx-auto" height="80px" src= "aris-en.png" alt = "ARIS"  v-if="language.current=='en'" />
            <img class="mx-auto" height="80px" src= "aris-sl.png" alt = "ARIS"  v-else />
            <p class="kreon" v-if="language.current=='en'">
                This application has been created within the framework of the programme Slovenian language in synchronous and diachronic development (P6-0038, 1. 1. 2022–31. 12. 2027), and the project i-SLA: interactive atlas of Slovenian dialects (L6-2628 , September 1, 2020–August 31, 2024), which are co-financed by ARIS.
            </p>
            <p class="kreon" v-else>
                Aplikacija je nastala v okviru programa Slovenski jezik v sinhronem in diahronem razvoju (P6-0038, 1. 1. 2022–31. 12. 2027) in projekta i-SLA – Interaktivni atlas slovenskih narečij (L6-2628, 1. 9. 2020–31. 8. 2024), ki ju sofinancira ARIS.
            </p>
            <q-separator size="1rem" color="white"/>
            <img class="mx-auto" height="50px" src= "rubin_logo_z_obrobo.jpg" alt = "Rubin geoinformatika d.o.o."/>
            <p class="kreon">
                &copy; {{ year }} - Rubin geoinformatika d.o.o.
            </p>

            <q-separator />
            <!-- h5>{{ $gettext("Version") }}</h5>
            <q-separator />
            <h5>GDPR</h5 -->
            <q-separator />
        </div>
    </q-page>
</template>

<!--
                <div class="text-center m-0 vh-50 d-flex flex-column justify-content-center text-dark">
                    <img class="mx-auto mb-4" width="500px" src= "{% static 'images/logo_diatlas.png' %}" alt = "DIAtlas" />
                    <p class="lead">{% trans 'DIAtlas je aplikacija za vnos, urejanje, analiziranje in kartiranje dialektoloških podatkov.' %}</p>
                    <img class="mx-auto mt-5" width="200px" src= "{% static 'images/Logotip ZRC SAZU.png' %}" alt = "ZRC SAZU" />
                    <p class="kreon">{% trans ' Inštitut za slovenski jezik Frana Ramovša ' %}</p>
                    <img class="mx-auto" height="80px" src= "{% static 'images/aris-sl.png' %}" alt = "ARIS"/>
                    <p class="kreon">
                        Aplikacija je nastala v okviru programa Slovenski jezik v sinhronem in diahronem razvoju (P6-0038, 1. 1. 2022–31. 12. 2027) in projekta i-SLA – Interaktivni atlas slovenskih narečij (L6-2628, 1. 9. 2020–31. 8. 2024), ki ju sofinancira ARIS.
                    </p>
                    <img class="mx-auto" height="50px" src= "{% static 'images/rubin_logo_z_obrobo.jpg' %}" alt = "ARIS"/>
                    <p class="kreon">
                        &copy; {{ year }} - {% trans 'Rubin geoinformatika d.o.o.' %}
                    </p>
                    </div>
                </div>

-->
<style scoped>
    .main {
        padding: 20px;
        height: calc(100vh - 151px);
    }
    h5 {
        margin-top: 20px;
    }
    q-separator {
        margin-top: 10px;
    }
</style>

<script>
import { ref } from "vue";
import { useGettext } from "vue3-gettext";
import store from '../store';


export default {
    name: "About",
    data() {
        console.log("About data");
        return {
            language: useGettext(),
        };
    },
    //computed: {
    //    ...mapState(["language"]),
    //},
};


</script>